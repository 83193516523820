import request from '@/api/request'

/* 数形菜单集合 */
export function reqOrg () { return request({ url: '/dept/tree' }) }

/* 查询 */
export function reqOrgSearch (params) { return request({ url: '/dept/page', params }) }

/* 新增 */
export function reqOrgAdd (params) { return request({ url: '/dept', method: 'post', params }) }

/* 修改 */
export function reqOrgUpdate (params) { return request({ url: '/dept', method: 'put', params }) }

/* 根据主键id查询 */
export function reqOrgGetById (id) { return request({ url: '/dept/:id', params: id }) }

/* 根据主键id查询 */
export function reqOrgDelById (id) { return request({ url: '/dept/:id', method: 'delete', params: id }) }

/* 根据主键查询子租户 */
export function reqOrgGetChildren (params) { return request({ url: '/tenant/filter/tree', params }) }
