<template>
    <div id="oegDrawer">
        <el-drawer
            title="新增"
            :visible.sync="drawerVisible"
            direction="rtl"
            :wrapperClosable="false"
            custom-class="cus-drawer"
        >
            <!-- <div class="cus_drawer_container"> -->
                <div class="cus_drawer_content">

                     <!-- 新增子组织 -->
                      <el-form
                        :model="formData"
                        label-position="right"
                        ref="formData"
                        :rules="rules"

                    >
                        <el-form-item label="组织名称" prop="name"
                            ><el-input
                                size="small"
                                v-model="formData.name"
                                placeholder="请输入组织名称"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="排序号" prop="orderNum"
                            ><el-input
                                size="small"
                                v-model="formData.orderNum"
                                placeholder="请输入排序号"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-select
                                size="small"
                                 style="width:100%"
                                v-model="formData.status"
                                placeholder="请选择"
                                ><el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                            ></el-select>
                        </el-form-item>
                        <el-form-item v-if="topOrg" label="父级部门" prop="parentName"
                            ><el-input
                                size="small"
                                v-model="parentData.name"
                                disabled
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="缩写" prop="abridge"
                            ><el-input
                                size="small"
                                v-model="formData.abridge"
                                placeholder="请输入"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="备注" prop="note"
                            ><el-input
                                size="small"
                                type="textarea"
                                v-model="formData.note"
                                placeholder="请输入"
                            ></el-input
                        ></el-form-item>
                    </el-form>
                <!-- </div> -->
                <footer class="cus_drawer_footer">
                    <el-button size="small" plain @click="cancel">取消</el-button>
                    <el-button size="small" type="primary" @click="submit">提交</el-button>
                </footer>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { reqOrgAdd } from '@/api/system/organization'
export default {
    /* 组织管理抽屉 */
    name: 'orgDrawer',
    props: {
        // 获取父级组织信息
        parentData: { type: Object },
        // 判断添加一级组织还是子组织
        topOrg: { type: Boolean, default: true },
        // 同级组织名称集合
        nameList: { type: Array },
        parent: { type: Boolean, default: false }
    },
    data () {
        const validateFn = (rule, value, callback) => {
            const { field } = rule
            if (field === 'name') {
                if (this.nameList.indexOf(this.formData.name) === -1) {
                    callback()
                } else {
                    callback(new Error('组织名称已存在'))
                }
            }
            callback()
        }

        return {
            drawerVisible: false,
            statusList: [
                { value: '0', label: '无效' },
                { value: '1', label: '有效' }
            ],
            // 填写规则
            rules: {
                // 组织名称
                name: [{ required: true, message: '请输入组织名称', trigger: 'blur' },
                    { trigger: 'blur', validator: validateFn }],
                // 排序号
                orderNum: [{ required: true, message: '请选择排序号', trigger: 'blur' }],
            },
            //  表单数据
            formData: {
                abbreviation: '', // 简称
                abridge: '', // --缩写
                deptLevel: '', // --层级
                id: '', // --主键
                name: '', // --部门名称
                note: '', // --备注
                orderNum: '', // --排序
                parentId: 0, // --父级部门
                status: '0', // --状态
                tenantId: '' // --租户主键标识
            },

        }
    },
    watch: {
        parentData () {

        }
    },
    methods: {
        cancel () {
            this.drawerVisible = false
            this.formData = {}
            // this.formData.parentId = 0
            this.$refs.formData.clearValidate()
        },
        submit () {
            this.$refs.formData.validate(valid => {
                if (valid) {
                    // console.log(this.parentData)
                    if (this.topOrg) {
                        this.formData.parentId = this.parentData.id
                    } else {
                        this.formData.parentId = 0
                    }
                    reqOrgAdd(this.formData)
                        .then(res => { this.$emit('callback'); this.cancel() })
                    // console.log(1)
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style></style>
