<template>
  <div id="Organization" class="padding15">
    <el-row :gutter="20">
      <el-col :span="inf">
        <!-- <div class="cus_table_top_operate_part">
                    <template>
                    </template>
                </div> -->
        <div>
          <el-button type="primary" size="small" @click="addChild">添加子组织</el-button>
          <el-button type="primary" size="small" @click="addTop">添加一级组织</el-button>
        </div>
        <div style="border:1px solid #DCDFE6;padding:5px; margin-top:10px;">
          <p style="margin:0">当前选择：
            <span style="color:#4A8DE6">{{ checkData }}
              <span v-if="checkData" @click="cancelSearch" style="cursor: pointer">取消选择</span></span>
          </p>
        </div>
        <el-input placeholder="请输入组织名称" size="small" v-model="filterText" style="margin-top:10px"></el-input>
        <!-- 组织树 -->
        <el-tree ref="orgTree" :data="orgData" :props="{ label: 'name', children: 'children' }" show-checkbox check-strictly node-key="id" @check="handleCheckChange" :filter-node-method="filterNode"></el-tree>
        <orgDrawer ref="orgDrawer" :parentData="formData" :topOrg="topOrg" @callback="getData" :nameList="nameList" />
      </el-col>
      <!-- 详情表单，可编辑状态 -->
      <el-col :span="formInf">
        <div class="cus_drawer_container">
          <div class="cus_drawer_content">
            <el-form :model="formData" label-width="120px" label-position="right" ref="FormData" :rules="rules">
              <el-form-item label="组织名称" prop="name">
                <el-input v-model="formData.name" size="small"></el-input>
              </el-form-item>
              <el-form-item label="排序号" prop="orderNum">
                <el-input v-model="formData.orderNum" size="small"></el-input>
              </el-form-item>
              <el-form-item label="状态" prop="status">
                <el-select v-model="formData.status" size="small" style="width:100%">
                  <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="父级部门" prop="parentName">
                <el-input v-model="parentName" size="small" disabled></el-input>
              </el-form-item>
              <el-form-item label="缩写" prop="abridge">
                <el-input v-model="formData.abridge" size="small"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="note">
                <el-input type="textarea" size="small" v-model="formData.note"></el-input>
              </el-form-item>
            </el-form>
          </div>

          <footer class="cus_drawer_footer">
            <el-button type="primary" size="small" plain @click="cancel">取消</el-button>
            <el-button type="primary" size="small" @click="submit">保存</el-button>
            <el-button type="primary" size="small" @click="del">删除</el-button>
          </footer>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import orgDrawer from './orgDrawer'
import {
    reqOrg,
    reqOrgUpdate,
    reqOrgGetById,
    reqOrgDelById,
} from '@/api/system/organization'
export default {
    /* 组织管理 */
    name: 'organization',
    components: {
        orgDrawer,
    },
    data () {
        const validateFn = (rule, value, callback) => {
            const { field } = rule
            if (field === 'name') {
                if (this.nameList.indexOf(this.formData.name) === -1) {
                    callback()
                } else {
                    callback(new Error('组织名称已存在'))
                }
            }
            callback()
        }
        return {
            inf: 24,
            formInf: 0,
            orgData: [],
            inline: true,
            filterText: '', // 搜索内容
            topOrg: true, // 一级组织---子组织
            //  表单数据
            formData: {
                abbreviation: '', // 简称
                abridge: '', // --缩写
                deptLevel: '', // --层级
                id: '', // --主键
                name: '', // --部门名称
                note: '', // --备注
                orderNum: '', // --排序
                parentId: 0, // --父级部门
                status: '0', // --状态
                tenantId: '', // --租户主键标识
            },
            parentName: '无', // 详情-父级部门名称
            // parentData: { parentName: '', parentId: 0 }, // 添加子组织-父级信息
            // parentForm: {}, // 获取选中部门时部门信息
            statusList: [
                { value: '0', label: '无效' },
                { value: '1', label: '有效' },
            ],
            // 填写规则
            rules: {
                // 组织名称
                name: [
                    { required: true, message: '请输入组织名称', trigger: 'blur' },
                    { trigger: 'blur', validator: validateFn },
                ],
                // 排序号
                orderNum: [
                    { required: true, message: '请选择排序号', trigger: 'blur' },
                ],
            },
            currentChangId: null, // 获取id值，用于判断并获取选中项详情
            checkData: '', // 当前选择部门名称
            nameList: [], // 同级组织名称集合，用于添加组织是时的判断
        }
    },
    created () {
        this.getData()
    },
    watch: {
        filterText (val) {
            this.$refs.orgTree.filter(val)
        },
    },
    methods: {
        getData (data) {
            this.loading = true
            reqOrg(data)
                .then((result) => {
                    this.orgData = result.data
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        // 当前选择的取消选择
        cancelSearch () {
            this.$refs.orgTree.setCheckedKeys([])
            this.checkData = ''
            this.cancel()
        },
        // 详情
        reqOrgGetById () {
            const { currentChangId } = this
            if (currentChangId) {
                reqOrgGetById({ id: currentChangId }).then((res) => {
                    this.formData = res.data
                    if (res.data.parentId !== 0) {
                        reqOrgGetById({ id: res.data.parentId }).then((res) => {
                            this.parentName = res.data.name
                        })
                    } else {
                        this.parentName = '无'
                    }
                })
            }
        },
        // 添加子组织
        addChild () {
            if (!this.currentChangId) {
                this.$message({
                    type: 'warning',
                    message: '至少选择一个父级组织！',
                })
            } else {
                this.$refs.orgDrawer.drawerVisible = true
                this.topOrg = true
            }
        },
        // 获取父级组织名称集合
        handleNameList () {
            this.nameList = []
            const arr = this.formData.children
            this.nameList.push(arr.map((v) => v.name))
            this.nameList = this.nameList[0]
        },
        // 多选框选择
        handleCheckChange (data, statusObj) {
            this.nameList = []
            let id = ''
            const len = statusObj.checkedKeys.length
            if (len > 0) {
                this.$refs.orgTree.setCheckedKeys([data.id])
                id = data.id
                this.inf = 11
                this.formInf = 11
                this.checkData = data.name
            } else {
                id = ''
                this.cancel()
                this.checkData = ''
            }
            this.currentChangId = id
            this.formData = data
            this.handleNameList()
            this.reqOrgGetById()
        },

        // 添加一级组织
        addTop () {
            this.orgData.forEach((index) => {
                this.nameList.push(index.name)
            })
            this.$refs.orgDrawer.drawerVisible = true
            this.topOrg = false
        },
        // 取消
        cancel () {
            this.inf = 24
            this.formInf = 0
            this.$refs.orgTree.setCheckedKeys([])
        },
        // 确认
        submit () {
            this.$refs.FormData.validate((valid) => {
                if (valid) {
                    reqOrgUpdate(this.formData).then((res) => {
                        this.getData()
                    })
                } else {
                    return false
                }
            })
        },
        del () {
            this.$confirm('是否确认删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    reqOrgDelById({ id: this.formData.id }).then((res) => {
                        this.getData()
                        this.$message({
                            type: 'success',
                            message: '删除成功',
                        })
                        this.cancel()
                    })
                })
                .catch(() => {})
        },
        // 搜索判断
        // 对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏
        filterNode (value, data) {
            if (!value) return true
            return data.name.indexOf(value) !== -1
        },
    },
}
</script>
<style lang="scss" scoped>
.cus_drawer_footer {
  text-align: right;
}
</style>
